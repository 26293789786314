import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Hizmetlerimiz</title>
        <meta
          name={"description"}
          content={"Birinci Sınıf Hizmetlerimizle Golf Deneyiminizi Yükseltin"}
        />
        <meta
          property={"og:title"}
          content={"Hizmetlerimiz | Regnum Golf Kulübü'nde Hizmetler"}
        />
        <meta
          property={"og:description"}
          content={"Birinci Sınıf Hizmetlerimizle Golf Deneyiminizi Yükseltin"}
        />
        <meta
          property={"og:image"}
          content={"https://yixelbrix.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://yixelbrix.com/img/5949968.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://yixelbrix.com/img/5949968.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://yixelbrix.com/img/5949968.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://yixelbrix.com/img/5949968.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://yixelbrix.com/img/5949968.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://yixelbrix.com/img/5949968.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://yixelbrix.com/img/3.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="670px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h2" font="--headline2">
            Golfte Mükemmellik Dünyası Sizi Bekliyor
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="50px"
            width="100%"
          />
          <Text font="--base" text-align="center">
            Regnum Golf Club'da kendimizi fairway'in ötesine geçen benzersiz bir
            golf deneyimi sunmaya adadık. Hizmet yelpazemiz, sahamıza adım
            attığınız andan 19. delikteki performansınız üzerine düşündüğünüz
            ana kadar golf yolculuğunuzun her yönüne hitap edecek şekilde
            tasarlanmıştır. Oyununuzu, boş zamanlarınızı ve golf tutkunuzu
            geliştirmenin sayısız yolunu keşfedin.
          </Text>
        </Box>
      </Section>
      <Section
        padding="120px 0 130px 0"
        lg-padding="80px 0 90px 0"
        quarkly-title="Stages/Steps-4"
      >
        <Text
          font="--headline2"
          margin="0px 0px 40px 0px"
          color="--darkL2"
          text-align="center"
        >
          Oyununuzu Geliştirmek ve Boş Zamanınızı Zenginleştirmek için Özel
          Olarak Tasarlandı
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          md-grid-template-columns="1fr"
          md-grid-template-rows="auto"
          md-grid-gap={0}
          lg-padding="0px 0 0px 0"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            padding="0px 50px 30px 0"
            sm-padding="0px 0 34px 0"
            lg-padding="0px 25px 0 0"
            md-margin="0px 0px 30px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
              <Text
                margin="0 0 15px 25px"
                border-color="--color-light"
                color="--darkL1"
                font="normal 500 22px/1.2 --fontFamily-sans"
                text-align="left"
              >
                Profesyonel Koçluk
              </Text>
              <Text
                margin="0 0 0 25px"
                font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
                color="#65696d"
                text-align="left"
                border-color="#7a7c7f"
              >
                Uzman Rehberliği: Sertifikalı golf profesyonellerinden
                kişiselleştirilmiş eğitim alın.
                <br />
                <br />
                Teknoloji Destekli Öğrenme: En son salınım analizi ve performans
                izleme teknolojisinden yararlanın.
                <br />
                <br />
                Gençlik Programları: Genç golf kliniklerimiz ve kamplarımızla
                genç nesli golfle tanıştırın.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            padding="0px 50px 30px 0"
            sm-padding="0px 0 34px 0"
            lg-padding="0px 25px 0 0"
            md-margin="0px 0px 30px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
              <Text
                margin="0 0 15px 25px"
                border-color="--color-light"
                color="--darkL1"
                font="normal 500 22px/1.2 --fontFamily-sans"
                text-align="left"
              >
                Özel Saha Erişimi
              </Text>
              <Text
                margin="0 0 0 25px"
                font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
                color="#65696d"
                text-align="left"
                border-color="#7a7c7f"
              >
                Öncelikli Rezervasyon: Üyeler öncelikli tee zamanlarından ve
                rezervasyonlardan yararlanır.
                <br />
                <br />
                Çeşitli Parkur Düzenleri: Birden fazla tee kutusu ve çeşitli
                delik tasarımlarıyla becerilerinize meydan okuyun.
                <br />
                <br />
                Bozulmamış Koşullar: Titizlikle korunan yeşilliklerimizde ve çim
                yollarımızda golfü en saf haliyle deneyimleyin.
              </Text>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            padding="0px 50px 30px 0"
            sm-padding="0px 0 0 0"
            lg-padding="0px 25px 0 0"
            md-margin="0px 0px 30px 0px"
            sm-margin="0px 0px 20px 0px"
          >
            <Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
              <Text
                margin="0 0 15px 25px"
                border-color="--color-light"
                color="--darkL1"
                font="normal 500 22px/1.2 --fontFamily-sans"
                text-align="left"
              >
                Özel Kulüp Ayarı
              </Text>
              <Text
                margin="0 0 0 25px"
                font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
                color="#65696d"
                text-align="left"
                border-color="#7a7c7f"
              >
                Hassas Ekipman: En son fitting teknolojisi kullanılarak
                vuruşunuza göre uyarlanmış sopalarla donatılın.
                <br />
                <br />
                Uzman Tavsiyesi: Fitting uzmanlarımız kişisel oyun tarzınıza
                göre tavsiyelerde bulunur.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-10"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
        >
          <Box
            overflow-x="hidden"
            overflow-y="hidden"
            transform="translateY(0px)"
            transition="transform 0.2s ease-in-out 0s"
            padding="0px 0px 70% 0px"
            width="100%"
            height="auto"
            position="relative"
            hover-transform="translateY(-10px)"
          >
            <Image
              left={0}
              src="https://yixelbrix.com/img/4.jpg"
              object-fit="cover"
              width="100%"
              top="auto"
              right={0}
              bottom="0px"
              min-height="100%"
              position="absolute"
              display="block"
            />
          </Box>
        </Box>
        <Box
          flex-direction="column"
          lg-width="100%"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          display="flex"
        >
          <Text
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            sm-font="--headline3"
            md-text-align="left"
          >
            Farkını Deneyimleyin
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            md-text-align="left"
          >
            Regnum Golf Club'da sunduğumuz her hizmet, golfte mükemmelliğin
            özüyle yoğrulmuştur. Son teknoloji tesislerimizden koçluk ve
            hizmetlerimizdeki kişisel dokunuşa kadar, kendimizi golf hayatınızı
            zenginleştirmeye adadık.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
